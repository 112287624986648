// overwrite bootstrap variables
$blue: #1a6c9b;
$orange: #ef7e3a;
$red: #9B1A1A;

$font-size-base: 0.875rem;
$font-family-base: 'Montserrat', sans-serif; 
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 600; 
$headings-font-weight: 600;
$paragraph-margin-bottom: 0.5rem;

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";