html,
body,
#root {
  margin: 0;
  height: 100%;
}

* {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.form-control {
  font-size: 13px !important;
  padding: 8px !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

._3zRJQ {
  font-weight: 600 !important;
}

.paragraph-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}