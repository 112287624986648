.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.react-datepicker__month-container {
    width: 300px;
}

.react-datepicker__triangle {
    transform: translate(155px, 0px) !important;
}

.react-datepicker__current-month {
    padding: 4px;
}

.react-datepicker__navigation {
    margin: 10px;
}

.react-datepicker__week {
    margin: 4px 0;
    display: flex;
    justify-content: space-evenly;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
}

.react-datepicker__day-name {
    margin: 0;
    width: auto;
}