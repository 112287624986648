.outlet-container {
    min-height: calc(100% - 64px);
    width: 100%;
    top: 64px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.outlet-container>div {
    padding: 16px 20px;
}

.user-menu>.dropdown-toggle::after {
    display: none !important;
}

.nav-link.active {
    font-weight: 500 !important;
}